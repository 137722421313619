import { useState, useRef, useEffect, useMemo, useImperativeHandle } from 'react';

function useAptor(ref, configuration, deps = []) {
  const [instance, setInstance] = useState(null);
  const domRef = useRef(null);
  const { instantiate, destroy, getAPI, params } = configuration;
  useEffect(() => {
    const instanceReference = instantiate(domRef.current, params);
    setInstance(instanceReference);
    return () => {
      if (destroy)
        destroy(instanceReference, params);
    };
  }, deps);
  const api = useMemo(() => getAPI(instance, params), [instance]);
  useImperativeHandle(ref, api, [api]);
  return domRef;
}

export { useAptor as default, useAptor };
