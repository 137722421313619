export let RHAP_UI = function (RHAP_UI) {
  RHAP_UI["CURRENT_TIME"] = "CURRENT_TIME";
  RHAP_UI["CURRENT_LEFT_TIME"] = "CURRENT_LEFT_TIME";
  RHAP_UI["PROGRESS_BAR"] = "PROGRESS_BAR";
  RHAP_UI["DURATION"] = "DURATION";
  RHAP_UI["ADDITIONAL_CONTROLS"] = "ADDITIONAL_CONTROLS";
  RHAP_UI["MAIN_CONTROLS"] = "MAIN_CONTROLS";
  RHAP_UI["VOLUME_CONTROLS"] = "VOLUME_CONTROLS";
  RHAP_UI["LOOP"] = "LOOP";
  RHAP_UI["VOLUME"] = "VOLUME";
  return RHAP_UI;
}({});